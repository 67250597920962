//
//
//
import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getEmploeesData = createAsyncThunk(
  "emploees/getEmploeesData",
  async (prop) => {
    const options = {
      url: `https://testhr.vodoglobal.com/api/v1/employees-skill?service_skill_id=${prop.serviceId}&branch_id=${prop.branchId}&name=`,
      method: "GET",
      headers: {
        "secure-business-key": "3717466d81c36d98ac4.30464285",
        uuid: "630ca2f4885f8",
      },
    };

    try {
      const { data } = await axios.request(options);
      // console.log(data);
      return data;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }
);

const initialValues = {
  getEmploees: null,
  isLoading: false,
  isError: null,
};

const getEmploeesSlice = createSlice({
  name: "emploees",
  initialState: initialValues,
  extraReducers: (builder) => {
    builder
      .addCase(getEmploeesData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getEmploees = action.payload;
        // console.log(action.payload);
      })
      .addCase(getEmploeesData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEmploeesData.rejected, (state, action) => {
        state.isError = action.error;
        state.isLoading = false;
        // console.log(action.error);
      });
  },
  reducers: {},
});

export const getEmploeesReducer = getEmploeesSlice.reducer;
