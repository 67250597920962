// //
// //
// //
// import { Link } from "react-router-dom";
// import React, { useEffect, useState } from "react";
// import { FiTrash2 } from "react-icons/fi";
// import { useDispatch, useSelector } from "react-redux";
// import { getDataCart } from "../redux/getFromCartSlice";
// import { changeQty } from "../redux/addToCartSlice";
// import { FaPlus, FaMinus, FaRegClock } from "react-icons/fa6";
// import { getEmploeesData } from "../redux/emploeesSlice";
// //
// export default function Cart() {
//   const dispatch = useDispatch();
//   const [proffessionalsModel, setProffessionalsModel] = useState(false);
//   const [selectedProfessional, setSelectedProfessional] = useState([]);
//   // console.log("selectedProfessional", selectedProfessional);
//   //
//   //====== this will get data products cart ========
//   const data = useSelector(
//     (state) => state?.getFromCart?.getCartItems?.data?.details
//   );
//   console.log("productsFromCart",data);
//   //
//   // ======= this will get data service employess =======
//   const employess = useSelector((state) => state?.getEmploees?.getEmploees?.response
// );
//   console.log("employess",employess);
//   //
//   useEffect(() => {
//     dispatch(getDataCart());
//     dispatch(getEmploeesData());
//   }, [dispatch]);
//   //

//   // ====== this will select professional ======
//   const handleSelectedProfessional = (id) => {
//     setProffessionalsModel(false);
//     const selected = employess?.find((item) => item?.user_id === id);
//     setSelectedProfessional(selected);
//   };
//   // ======= this will get service id and branch id and get employess  ======
//   const handleGetEmployees = (serviceId , branchId) => {
//     dispatch(getEmploeesData({ serviceId, branchId }));
//   };
//   //
//   // ===== these functions will increase, decrease and remove =====
//   const handleIncreaseQty = (qty, id) => {
//     if (qty > 0) {
//       dispatch(changeQty({ state: "increase", id }));
//       dispatch(getDataCart());
//     }
//   };
//   //
//   const handleDecreaseQty = (qty, id) => {
//     if (qty > 0) {
//       dispatch(changeQty({ state: "decrease", id }));
//       dispatch(getDataCart());
//     } else if (qty === 0) {
//       dispatch(changeQty("remove", id));
//       dispatch(getDataCart());
//     }
//   };
//   //
//   const handleRemove = (id) => {
//     dispatch(changeQty({ state: "remove", id }));
//     dispatch(getDataCart());
//   };
//   return (
//     <section className="flex flex-col justify-between h-full">
//       {data && data.length > 0 ? (
//         <>
//           <div className="container-box h-[62vh] md:h-[53vh] lg:h-[42vh] flex flex-col gap-3 py-6 overflow-y-auto">
//             {data?.map((data, i) => (
//               <div key={i} className="">
//                 <div className="shadow-lg p-3 rounded-xl">
//                   <div className="flex justify-between items-center pb-2">
//                     <div className="flex items-center gap-4">
//                       <div className=" w-[80px] h-[80px] md:w-[100px] md:h-[100px] lg:w-[120px] lg:h-[120px] rounded-xl bg-slate-300">
//                         <img
//                           src={data?.product?.image}
//                           alt={data?.name}
//                           className="w-full object-cover rounded-xl"
//                         />
//                       </div>
//                       <div className="">
//                         <h3 className="text-[28px] font-bold">{data?.name}</h3>
//                         <p className="flex items-center gap-1 text-gray-400 text-[24px] md:text-[28px] lg:text-[32px]">
//                           <FaRegClock className="" />
//                           45 min
//                         </p>
//                       </div>
//                     </div>

//                     {/* this will remove the item */}
//                     <div
//                       onClick={() => handleRemove(data?.id)}
//                       className="flex justify-center items-center bg-red-100 cursor-pointer rounded-[18px] h-[56px] w-[56px] md:rounded-[20px] md:h-[64px] md:w-[64px] lg:rounded-[24px] lg:h-[72px] lg:w-[72px]"
//                     >
//                       <FiTrash2 className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 text-red-400" />
//                     </div>
//                   </div>

//                   {/* this will select professional */}
//                   {data?.product?.type === "Service" && (
//                     <div
//                       onClick={() => {
//                         setProffessionalsModel(true);
//                         handleGetEmployees(data?.product?.id, data?.branchId);
//                       }}
//                       className="flex justify-between items-center py-4 cursor-pointer"
//                     >
//                       <div className="flex items-center justify-between gap-2  p-2 bg-[#FAF9F5] rounded-2xl w-full">
//                         <div className="flex items-center gap-2">
//                           <div className="flex justify-center items-center bg-white cursor-pointer rounded-[18px] h-[56px] w-[56px] md:rounded-[20px] md:h-[64px] md:w-[64px] lg:rounded-[24px] lg:h-[72px] lg:w-[72px]">
//                             <img
//                               src="./images/user-plus.png"
//                               alt="user plus"
//                               className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 text-stone-400"
//                             />
//                           </div>
//                           <p className="lg:text-[28px] font-semibold text-main">
//                             Select professional
//                           </p>
//                         </div>
//                         {selectedProfessional?.length === 0 &&
//                         proffessionalsModel == false ? (
//                           <div className="flex justify-center items-center cursor-pointer rounded-[18px] h-[56px] w-[56px] md:rounded-[20px] md:h-[64px] md:w-[64px] lg:rounded-[24px] lg:h-[72px] lg:w-[72px]">
//                             <img
//                               src="./images/chevron-right.png"
//                               alt="chevron right"
//                               className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10"
//                             />
//                           </div>
//                         ) : (
                            
//                           <div className="flex items-center gap-4">
//                             <p className="font-bold text-[16px] md:text-[20px] lg:text-[24px]">
//                               {selectedProfessional?.user_name}
//                             </p>
//                             <img
//                               src={selectedProfessional?.profile_url}
//                               alt={selectedProfessional?.user_name}
//                               className="w-8 h-8 md:w-12 md:h-12 lg:w-16 lg:h-16 rounded-full object-cover object-center"
//                             />
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                   )}

//                   {/* this will increase and decrease the qty */}
//                   <div className="flex justify-between items-center border-t-2 pt-2">
//                     <p className="text-main text-[16px] md:text-[20px] lg:text-[24px] font-bold">
//                       {data?.currancy || "SRA"} {data?.price}
//                     </p>
//                     <div className="flex items-center gap-6">
//                       <div
//                         // this will decrease the qty
//                         onClick={() => handleDecreaseQty(data?.qty, data?.id)}
//                         className="flex justify-center items-center bg-secondary cursor-pointer rounded-[18px] h-[56px] w-[56px] md:rounded-[20px] md:h-[64px] md:w-[64px] lg:rounded-[24px] lg:h-[72px] lg:w-[72px]"
//                       >
//                         <FaMinus className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 text-stone-400" />
//                       </div>
//                       <p className="lg:text-[28px] font-semibold">
//                         {data?.qty}
//                       </p>
//                       <div
//                         // this will increase the qty
//                         onClick={() => handleIncreaseQty(data?.qty, data?.id)}
//                         className="flex justify-center items-center bg-secondary cursor-pointer rounded-[18px] h-[56px] w-[56px] md:rounded-[20px] md:h-[64px] md:w-[64px] lg:rounded-[24px] lg:h-[72px] lg:w-[72px]"
//                       >
//                         <FaPlus className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 text-stone-400" />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//           {/* this will show the total */}
//           <div className="w-full px-6 md:px-12 pb-6 pt-4 bg-secondary rounded-t-2xl">
//             <div className="flex items-center justify-between w-full">
//               <p className="text-textBold text-4xl font-semibold">
//                 {data?.reduce((a, b) => a + b.qty, 0)} items
//               </p>
//               <p className="text-main text-4xl font-bold">
//                 SRA {data?.reduce((a, b) => a + b.price * b.qty, 0)}
//               </p>
//             </div>
//             <p className="mt-4 text-center font-semibold  lg:text-[32px] uppercase bg-main text-white py-4 w-full rounded-2xl">
//               Book Appointment
//             </p>
//           </div>
//         </>
//       ) : (
//         // this will show the empty cart
//         <div className="h-full flex items-center justify-center py-6">
//           <div className="w-[80vw] md:w-[60vw] lg:w-[40vw] space-y-10">
//             <div className="w-full">
//               <img
//                 src="./images/empty.png"
//                 alt="empty-cart"
//                 className="w-full"
//               />
//             </div>
//             <div className="">
//               <h3 className="text-center text-[30px] md:text-[38px] lg:text-[44px] font-bold">
//                 Your Cart Is Empty
//               </h3>
//               <p className="text-center text-[16px] md:text-[18px] lg:text-[22px] text-main">
//                 It looks like you haven't added any services to your cart yet.
//                 Start exploring our services now!
//               </p>
//               <p className="mt-8 text-center font-semibold text-[18px] md:text-[20px] lg:text-[24px] uppercase bg-main text-white py-2 w-full rounded-2xl">
//                 <Link to={"/home"}>Explore services</Link>
//               </p>
//             </div>
//           </div>
//         </div>
//       )}
//       {/* this will show the professional */}
//       <div
//         className={`${proffessionalsModel ? "" : "hidden"}
//           bg-opacity-50 absolute w-full bg-black  bottom-0 left-0 pt-52 transition-opacity duration-200`}
//         id="proffessionalsModel"
//       >
//         <div
//           className={`
//               bottom-0 left-0 opacity-100
//               absolute bg-gray-50 w-full rounded-t-2xl overflow-hidden transition-all duration-200`}
//           id="modelContent"
//         >
//           <div className="pt-10 pb-6 w-full bg-white relative">
//             <div className="pb-6 flex justify-center items-center w-full relative">
//               <div
//                 onClick={() => setProffessionalsModel(false)}
//                 className="absolute left-10 flex justify-center items-center cursor-pointer rounded-[18px] h-[56px] w-[56px] md:rounded-[20px] md:h-[64px] md:w-[64px] lg:rounded-[24px] lg:h-[72px] lg:w-[72px]"
//               >
//                 <img
//                   src="./images/x mark.png"
//                   alt="x mark"
//                   className="w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20"
//                 />
//               </div>
//               <h4 className="self-center font-bold text-[20px] md:text-[30px] lg:text-[40px]">
//                 Select professional
//               </h4>
//             </div>
//             {/* here is the search bar  */}
//             <div className="px-6 md:px-12">
//               <div className="flex justify-between items-center w-full border-2 border-gray-200 p-4 rounded-xl">
//                 <div className="flex items-center gap-3 flex-grow">
//                   <img
//                     src="./images/search.png"
//                     alt="search"
//                     className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10"
//                   />
//                   <input
//                     type="text"
//                     placeholder="Search"
//                     className="w-full text-[16px] md:text-[20px] lg:text-[24px] focus:outline-none "
//                   />
//                 </div>
//                 <div className="flex justify-center items-center">
//                   <img
//                     src="./images/vector.png"
//                     alt="chevron right"
//                     className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10"
//                   />
//                   <p className=" border-e-2 border-gray-300 pe-2 h-[24px] me-2"></p>
//                   <img
//                     src="./images/filter.png"
//                     alt="chevron right"
//                     className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10"
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* here is the professionals list */}
//           <div
//             className="h-[68vh] w-full grid grid-cols-3 gap-6 px-6 md:px-12 py-8 overflow-y-auto"
//             id="proffessionals"
//           >
//             {employess?.map((item, i) => (
//               <div
//                 onClick={() => handleSelectedProfessional(item?.user_id)}
//                 key={i}
//                 className="cursor-pointer rounded-3xl bg-white shadow-md shadow-slate-100 h-fit"
//               >
//                 <div className="w-full  h-[250px]">
//                   <img
//                     src={item?.profile_url}
//                     alt={item?.user_name}
//                     className="w-full h-full object-cover rounded-t-3xl"
//                   />
//                 </div>
//                 <div className="p-4 space-y-2">
//                   <div className="flex items-center gap-3">
//                     <p className="flex items-center gap-1 text-main font-semibold text-2xl">
//                       <img
//                         src="../images/star.png"
//                         alt="star"
//                         className="w-4 h-4 md:w-5 md:h-5 lg:w-7 lg:h-7"
//                       />
//                       {item?.rate}
//                     </p>
//                     <p className="text-gray-400 text-2xl">({item?.review})</p>
//                   </div>
//                   <p className=" font-semibold text-3xl">{item?.user_name}</p>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }




import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { FaPlus, FaMinus, FaRegClock } from "react-icons/fa6";
import { getEmploeesData } from "../redux/emploeesSlice";
import { useDispatch, useSelector } from "react-redux";
import LogoutBtn from "../components/logoutBtn";
//
export default function Cart() {
  const dispatch = useDispatch();
  const [cartItems, setCartItems] = useState({
    product: [],
    product_qty: [],
    professionals: [],
  });
  console.log("cartItems", cartItems?.product);
  //
  const [serviceSelectedId, setServiceSelectedId] = useState(null);
  const [proffessionalsModel, setProffessionalsModel] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

  // ======= this will get data service employess =======
  const employess = useSelector(
    (state) => state?.getEmploees?.getEmploees?.response
  );
  // console.log("employess", employess);
  //
  useEffect(() => {
    dispatch(getEmploeesData());
  }, [dispatch]);
  //
  useEffect(() => {
    const productsInSession = JSON.parse(sessionStorage.getItem("cartItems"));

    if (productsInSession) {
      setCartItems({
        product: productsInSession.product,
        product_qty:
          productsInSession.product_qty ||
          productsInSession.product.map((product) => product.qty || 1),
        professionals: productsInSession.professionals || [],
      });
    }
  }, []);

  const handleIncreaseQty = (index) => {
    const updatedProducts = [...cartItems.product];
    const updatedQty = [...cartItems.product_qty];
    const updatedProfessionals = [...cartItems.professionals];

    // تحقق من أن الكمية هي رقم، إذا كانت غير معرّفة أو NaN، عيّنها على 1 أولاً
    if (isNaN(updatedProducts[index].qty)) {
      updatedProducts[index].qty = 1;
    }
    updatedProducts[index].qty += 1;
    updatedQty[index] = updatedProducts[index].qty;

    sessionStorage.setItem(
      "cartItems",
      JSON.stringify({
        product: updatedProducts,
        product_qty: updatedQty,
        professionals: updatedProfessionals,
      })
    );
    setCartItems({
      product: updatedProducts,
      product_qty: updatedQty,
      professionals: updatedProfessionals,
    });
  };

  const handleDecreaseQty = (index) => {
    const updatedProducts = [...cartItems.product];
    const updatedQty = [...cartItems.product_qty];
    const updatedProfessionals = [...cartItems.professionals];

    if (isNaN(updatedProducts[index].qty)) {
      updatedProducts[index].qty = 1;
    }

    if (updatedProducts[index].qty > 1) {
      updatedProducts[index].qty -= 1;
      updatedQty[index] = updatedProducts[index].qty;
    }

    sessionStorage.setItem(
      "cartItems",
      JSON.stringify({
        product: updatedProducts,
        product_qty: updatedQty,
        professionals: updatedProfessionals,
      })
    );
    setCartItems({
      product: updatedProducts,
      product_qty: updatedQty,
      professionals: updatedProfessionals,
    });
  };

    const handleRemove = (index) => {
      const updatedProducts = [...cartItems.product];
      const updatedQty = [...cartItems.product_qty];
      const updatedProfessionals = [...cartItems.professionals];

      updatedProducts.splice(index, 1);
      updatedQty.splice(index, 1);
      updatedProfessionals.splice(index, 1);

      sessionStorage.setItem(
        "cartItems",
        JSON.stringify({
          product: updatedProducts,
          product_qty: updatedQty,
          professionals: updatedProfessionals,
        })
      );
      setCartItems({
        product: updatedProducts,
        product_qty: updatedQty,
        professionals: updatedProfessionals,
      });
    };

  // ===== this will calculate total =====
  const totalItems = cartItems?.product_qty?.reduce(
    (total, qty) => total + qty,
    0
  );
  const totalPrice =
    Math.round(
      cartItems?.product?.reduce(
        (total, product, index) =>
          total + product.price * cartItems.product_qty[index],
        0
      ) * 10
    ) / 10;

  // ====== this will select professional ======

  const handleSelectedProfessional = (id) => {
    const selectedProfessional = employess.find((emp) => emp.user_id === id);
    const updatedProfessionals = [...cartItems.professionals];
    const productIndex = cartItems.product.findIndex(
      (item) => item.id === serviceSelectedId
    );

    updatedProfessionals[productIndex] = selectedProfessional || null;

    sessionStorage.setItem(
      "cartItems",
      JSON.stringify({
        ...cartItems,
        professionals: updatedProfessionals,
      })
    );
    setCartItems({ ...cartItems, professionals: updatedProfessionals });
    setProffessionalsModel(false);
  };
  const handleGetEmployees = (serviceId) => {
    const branchId = sessionStorage.getItem("brunch_id");
    setServiceSelectedId(serviceId);
    dispatch(getEmploeesData({ serviceId, branchId }));
    setProffessionalsModel(true);
  };

  return (
    <>
      <section className="flex flex-col justify-between h-full">
        {cartItems?.product?.length > 0 ? (
          <>
          <LogoutBtn />
            <div className="container-box min-h-[62vh] md:min-h-[53vh] lg:min-h-[42vh] flex flex-col gap-3 py-6 overflow-y-auto">
              {cartItems?.product?.map((data, i) => {
                const professional = cartItems?.professionals?.[i];
        
                return (
                  <div key={i} className="">
                    <div className="shadow-lg p-3 rounded-xl">
                      <div className="flex justify-between items-center pb-2">
                        <div className="flex items-center gap-4">
                          <div className=" w-[80px] h-[80px] md:w-[100px] md:h-[100px] lg:w-[120px] lg:h-[120px] rounded-xl bg-slate-300">
                            <img
                              src={data?.image}
                              alt={data?.name}
                              className="w-full h-full object-center object-cover rounded-xl"
                            />
                          </div>
                          <div className="">
                            <h3 className="text-[28px] font-bold">
                              {data?.descriptions[0]?.name}
                            </h3>
                            <p className="flex items-center gap-1 text-gray-400 text-[24px] md:text-[28px] lg:text-[32px]">
                              <FaRegClock className="" />
                              45 min
                            </p>
                          </div>
                        </div>

                        {/* هنا زر حذف العنصر */}
                        <div
                          onClick={() => handleRemove(i)}
                          className="flex justify-center items-center bg-red-100 cursor-pointer rounded-[18px] h-[56px] w-[56px] md:rounded-[20px] md:h-[64px] md:w-[64px] lg:rounded-[24px] lg:h-[72px] lg:w-[72px]"
                        >
                          <FiTrash2 className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 text-red-400" />
                        </div>
                      </div>
                      {/* this will select professional */}
                      {data?.type == "service" && (
                        <div
                          onClick={() => {
                            setProffessionalsModel(true);
                            handleGetEmployees(data?.id);
                          }}
                          className="flex justify-between items-center py-4 cursor-pointer"
                        >
                          <div className="flex items-center justify-between gap-2  p-2 bg-[#FAF9F5] rounded-2xl w-full">
                            <div className="flex items-center gap-2">
                              <div className="flex justify-center items-center bg-white cursor-pointer rounded-[18px] h-[56px] w-[56px] md:rounded-[20px] md:h-[64px] md:w-[64px] lg:rounded-[24px] lg:h-[72px] lg:w-[72px]">
                                <img
                                  src="./images/user-plus.png"
                                  alt="user plus"
                                  className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 text-stone-400"
                                />
                              </div>
                              <p className="lg:text-[28px] font-semibold text-main">
                                Select professional
                              </p>
                            </div>
                            {professional ? (
                              <div className="flex items-center gap-4">
                                <p className="font-bold text-[16px] md:text-[20px] lg:text-[24px]">
                                  {professional?.user_name}
                                </p>
                                <img
                                  src={professional?.profile_url}
                                  alt={professional?.user_name}
                                  className="w-8 h-8 md:w-12 md:h-12 lg:w-16 lg:h-16 rounded-full object-cover object-center"
                                />
                              </div>
                            ) : (
                              <div className="flex justify-center items-center cursor-pointer rounded-[18px] h-[56px] w-[56px] md:rounded-[20px] md:h-[64px] md:w-[64px] lg:rounded-[24px] lg:h-[72px] lg:w-[72px]">
                                <img
                                  src="./images/chevron-right.png"
                                  alt="chevron right"
                                  className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {/* هنا زيادة و تقليل الكمية */}
                      <div className="flex justify-between items-center border-t-2 pt-2">
                        <p className="text-main text-[16px] md:text-[20px] lg:text-[24px] font-bold">
                          {data?.currancy || "SRA"} {data?.price}
                        </p>
                        <div className="flex items-center gap-6">
                          <div
                            onClick={() => handleDecreaseQty(i)}
                            className="flex justify-center items-center bg-secondary cursor-pointer rounded-[18px] h-[56px] w-[56px] md:rounded-[20px] md:h-[64px] md:w-[64px] lg:rounded-[24px] lg:h-[72px] lg:w-[72px]"
                          >
                            <FaMinus className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 text-stone-400" />
                          </div>
                          <p className="lg:text-[28px] font-semibold">
                            {cartItems.product_qty[i]}
                          </p>
                          <div
                            onClick={() => handleIncreaseQty(i)}
                            className="flex justify-center items-center bg-secondary cursor-pointer rounded-[18px] h-[56px] w-[56px] md:rounded-[20px] md:h-[64px] md:w-[64px] lg:rounded-[24px] lg:h-[72px] lg:w-[72px]"
                          >
                            <FaPlus className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 text-stone-400" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );})}
            </div>

            {/* هنا عرض الإجمالي */}
            <div className="w-full px-6 md:px-12 pb-6 pt-4 bg-secondary rounded-t-2xl">
              <div className="flex items-center justify-between w-full">
                <p className="text-textBold text-4xl font-semibold">
                  {totalItems} items
                </p>
                <p className="text-main text-4xl font-bold">SRA {totalPrice}</p>
              </div>
              <Link to="/phoneVerification">
                <p className="mt-4 text-center font-semibold lg:text-[32px] uppercase bg-main text-white py-4 w-full rounded-2xl">
                  Book Appointment
                </p>
              </Link>
            </div>
          </>
        ) : (
          // this will show when the cart is empty
          <div className="h-full flex items-center justify-center py-6">
            <div className="w-[80vw] md:w-[60vw] lg:w-[40vw] space-y-10">
              <div className="w-full">
                <img
                  src="./images/empty.png"
                  alt="empty-cart"
                  className="w-full"
                />
              </div>
              <div className="">
                <h3 className="text-center text-[30px] md:text-[38px] lg:text-[44px] font-bold">
                  Your Cart Is Empty
                </h3>
                <p className="text-center text-[16px] md:text-[18px] lg:text-[22px] text-main">
                  It looks like you haven't added any services to your cart yet.
                  Start exploring our services now!
                </p>
                <p className="mt-8 text-center font-semibold text-[18px] md:text-[20px] lg:text-[24px] uppercase bg-main text-white py-2 w-full rounded-2xl">
                  <Link to={"/home"}>Explore services</Link>
                </p>
              </div>
            </div>
          </div>
        )}
        {/* this will show the professional */}
        <div
          className={`${proffessionalsModel ? "" : "hidden"}
            bg-opacity-50 absolute w-full bg-black  bottom-0 left-0 pt-52 transition-opacity duration-200`}
          id="proffessionalsModel"
        >
          <div
            className={`
              bottom-0 left-0 opacity-100 absolute bg-gray-50 w-full
              rounded-t-2xl overflow-hidden transition-all duration-200`}
            id="modelContent"
          >
            <div className="pt-10 pb-6 w-full bg-white relative">
              <div className="pb-6 flex justify-center items-center w-full relative">
                <div
                  onClick={() => setProffessionalsModel(false)}
                  className="absolute left-10 flex justify-center items-center cursor-pointer rounded-[18px] h-[56px] w-[56px] md:rounded-[20px] md:h-[64px] md:w-[64px] lg:rounded-[24px] lg:h-[72px] lg:w-[72px]"
                >
                  <img
                    src="./images/x mark.png"
                    alt="x mark"
                    className="w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20"
                  />
                </div>
                <h4 className="self-center font-bold text-[20px] md:text-[30px] lg:text-[40px]">
                  Select professional
                </h4>
              </div>
              {/* here is the search bar  */}
              <div className="px-6 md:px-12">
                <div className="flex justify-between items-center w-full border-2 border-gray-200 p-4 rounded-xl">
                  <div className="flex items-center gap-3 flex-grow">
                    <img
                      src="./images/search.png"
                      alt="search"
                      className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10"
                    />
                    <input
                      type="text"
                      placeholder="Search"
                      className="w-full text-[16px] md:text-[20px] lg:text-[24px] focus:outline-none "
                    />
                  </div>
                  <div className="flex justify-center items-center">
                    <img
                      src="./images/vector.png"
                      alt="chevron right"
                      className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10"
                    />
                    <p className=" border-e-2 border-gray-300 pe-2 h-[24px] me-2"></p>
                    <img
                      src="./images/filter.png"
                      alt="chevron right"
                      className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* here is the professionals list */}
            <div
              className="h-[56vh] md:h-[52vh] lg:h-[45vh] w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-6 px-6 md:px-12 py-8 overflow-y-auto"
              id="proffessionals"
            >
              {employess &&
                employess?.map((employee, i) => (
                  <div
                    onClick={() => {
                      setSelectedEmployeeId(employee?.user_id);
                    }}
                    key={i}
                    className={`${
                      selectedEmployeeId === employee?.user_id
                        ? "border-2 border-main"
                        : ""
                    } cursor-pointer rounded-3xl bg-white shadow-md shadow-slate-100 h-fit`}
                  >
                    <div className="w-full  h-[250px]">
                      <img
                        src={employee?.profile_url}
                        alt={employee?.user_name}
                        className="w-full h-full object-cover rounded-t-3xl"
                      />
                    </div>
                    <div className="p-4 space-y-2">
                      <div className="flex items-center gap-3">
                        <p className="flex items-center gap-1 text-main font-semibold text-2xl">
                          <img
                            src="../images/star.png"
                            alt="star"
                            className="w-4 h-4 md:w-5 md:h-5 lg:w-7 lg:h-7"
                          />
                          {employee?.rate}
                        </p>
                        <p className="text-gray-400 text-2xl">
                          ({employee?.review})
                        </p>
                      </div>
                      <p className=" font-semibold text-3xl">
                        {employee?.user_name}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
            <div className="px-6 md:px-12 py-2">
              <p
                onClick={() => {
                  handleSelectedProfessional(selectedEmployeeId);
                }}
                className="text-center font-semibold lg:text-[32px] uppercase bg-main text-white py-4 w-full rounded-2xl"
              >
                Comfirm
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}


// import { Link } from "react-router-dom";
// import React, { useEffect, useState } from "react";
// import { FiTrash2 } from "react-icons/fi";
// import { FaPlus, FaMinus, FaRegClock } from "react-icons/fa6";
// import { getEmploeesData } from "../redux/emploeesSlice";
// import { useDispatch, useSelector } from "react-redux";

// export default function Cart() {
//   const dispatch = useDispatch();
  
//   // Session storage for products, quantity, and professionals
//   const [cartItems, setCartItems] = useState({
//     product: [],
//     product_qty: [],
//     professionals: [],
//   });

//   // Employees and Professional handling
//   const [proffessionalsModel, setProffessionalsModel] = useState(false);
//   const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
//   const [serviceSelectedId, setServiceSelectedId] = useState(null);
  
//   // Fetch employees from redux
//   const employees = useSelector(
//     (state) => state?.getEmploees?.getEmploees?.response
//   );

//   useEffect(() => {
//     dispatch(getEmploeesData());
//   }, [dispatch]);

//   // Retrieve data from session storage on component mount
//   useEffect(() => {
//     const productsInSession = JSON.parse(sessionStorage.getItem("cartItems"));

//     if (productsInSession) {
//       setCartItems({
//         product: productsInSession.product || [],
//         product_qty: productsInSession.product_qty || [],
//         professionals: productsInSession.professionals || [],
//       });
//     }
//   }, []);

//   // Add selected professional to the correct index in session storage
//   const handleSelectedProfessional = (id) => {
//     const selectedProfessional = employees.find((emp) => emp.user_id === id);
//     const updatedProfessionals = [...cartItems.professionals];
//     const productIndex = cartItems.product.findIndex(
//       (item) => item.id === serviceSelectedId
//     );

//     updatedProfessionals[productIndex] = selectedProfessional || null;

//     sessionStorage.setItem(
//       "cartItems",
//       JSON.stringify({
//         ...cartItems,
//         professionals: updatedProfessionals,
//       })
//     );
//     setCartItems({ ...cartItems, professionals: updatedProfessionals });
//     setProffessionalsModel(false);
//   };

//   // Handle quantity changes
//   const handleIncreaseQty = (index) => {
//     const updatedProducts = [...cartItems.product];
//     const updatedQty = [...cartItems.product_qty];

//     updatedQty[index] = (updatedQty[index] || 1) + 1;
//     updatedProducts[index].qty = updatedQty[index];

//     sessionStorage.setItem(
//       "cartItems",
//       JSON.stringify({ ...cartItems, product_qty: updatedQty })
//     );
//     setCartItems({ ...cartItems, product_qty: updatedQty });
//   };

//   const handleDecreaseQty = (index) => {
//     const updatedProducts = [...cartItems.product];
//     const updatedQty = [...cartItems.product_qty];

//     if (updatedQty[index] > 1) {
//       updatedQty[index] -= 1;
//       updatedProducts[index].qty = updatedQty[index];
//     } else {
//       updatedProducts.splice(index, 1);
//       updatedQty.splice(index, 1);
//       cartItems.professionals.splice(index, 1);
//     }

//     sessionStorage.setItem(
//       "cartItems",
//       JSON.stringify({
//         product: updatedProducts,
//         product_qty: updatedQty,
//         professionals: cartItems.professionals,
//       })
//     );
//     setCartItems({
//       product: updatedProducts,
//       product_qty: updatedQty,
//       professionals: cartItems.professionals,
//     });
//   };

//   const handleRemove = (index) => {
//     const updatedProducts = [...cartItems.product];
//     const updatedQty = [...cartItems.product_qty];
//     const updatedProfessionals = [...cartItems.professionals];

//     updatedProducts.splice(index, 1);
//     updatedQty.splice(index, 1);
//     updatedProfessionals.splice(index, 1);

//     sessionStorage.setItem(
//       "cartItems",
//       JSON.stringify({
//         product: updatedProducts,
//         product_qty: updatedQty,
//         professionals: updatedProfessionals,
//       })
//     );
//     setCartItems({
//       product: updatedProducts,
//       product_qty: updatedQty,
//       professionals: updatedProfessionals,
//     });
//   };

//   // Total calculation
//   const totalItems = cartItems.product_qty.reduce((total, qty) => total + qty, 0);
//   const totalPrice = cartItems.product.reduce(
//     (total, product, index) => total + product.price * cartItems.product_qty[index],
//     0
//   );

//   // Fetch employees for a service
//   const handleGetEmployees = (serviceId) => {
//     setServiceSelectedId(serviceId);
//     dispatch(getEmploeesData({ serviceId }));
//     setProffessionalsModel(true);
//   };

//   return (
//     <section className="flex flex-col justify-between h-full">
//       {cartItems.product.length > 0 ? (
//         <>
//           <div className="container-box flex flex-col gap-3 py-6 overflow-y-auto">
//             {cartItems.product.map((data, i) => (
//               <div key={i} className="shadow-lg p-3 rounded-xl">
//                 <div className="flex justify-between items-center pb-2">
//                   <div className="flex items-center gap-4">
//                     <div className="rounded-xl bg-slate-300">
//                       <img
//                         src={data.image}
//                         alt={data.name}
//                         className="w-full h-full rounded-xl"
//                       />
//                     </div>
//                     <div>
//                       <h3 className="text-[28px] font-bold">{data.name}</h3>
//                       <p className="flex items-center gap-1 text-gray-400 text-[24px]">
//                         <FaRegClock /> 45 min
//                       </p>
//                     </div>
//                   </div>
//                   <div
//                     onClick={() => handleRemove(i)}
//                     className="cursor-pointer"
//                   >
//                     <FiTrash2 className="text-red-400" />
//                   </div>
//                 </div>

//                 {data.type === "service" && (
//                   <div
//                     onClick={() => handleGetEmployees(data.id)}
//                     className="cursor-pointer"
//                   >
//                     <p>Select Professional</p>
//                     {cartItems.professionals[i] ? (
//                       <p>{cartItems.professionals[i].user_name}</p>
//                     ) : (
//                       <p>No professional selected</p>
//                     )}
//                   </div>
//                 )}

//                 <div className="flex justify-between items-center border-t-2 pt-2">
//                   <p>
//                     {data.currancy || "SRA"} {data.price}
//                   </p>
//                   <div className="flex items-center gap-6">
//                     <div
//                       onClick={() => handleDecreaseQty(i)}
//                       className="cursor-pointer"
//                     >
//                       <FaMinus />
//                     </div>
//                     <p>{cartItems.product_qty[i]}</p>
//                     <div
//                       onClick={() => handleIncreaseQty(i)}
//                       className="cursor-pointer"
//                     >
//                       <FaPlus />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>

//           <div className="px-6 py-4 bg-secondary rounded-t-2xl">
//             <div className="flex items-center justify-between">
//               <p>{totalItems} items</p>
//               <p>SRA {totalPrice}</p>
//             </div>
//             <Link to="/phoneVerification">
//               <p className="bg-main text-white text-center py-4 rounded-2xl">
//                 Book Appointment
//               </p>
//             </Link>
//           </div>
//         </>
//       ) : (
//         <div className="h-full flex items-center justify-center py-6">
//           <div>
//             <h3>Your Cart Is Empty</h3>
//             <Link to={"/home"}>Explore services</Link>
//           </div>
//         </div>
//       )}

//       {/* Professional selection modal */}

//       <div
//         className={`${proffessionalsModel ? "" : "hidden"}
//           bg-opacity-50 absolute w-full bg-black  bottom-0 left-0 pt-52 transition-opacity duration-200`}
//         id="proffessionalsModel"
//       >
//         {" "}
//         <div className="absolute bottom-0 left-0 bg-gray-50 w-full rounded-t-2xl">
//           <div className="pt-10 pb-6">
//             <div>
//               <h4>Select professional</h4>
//             </div>
//             <div className="px-6">
//               <input type="text" placeholder="Search" />
//             </div>
//           </div>

//           {/* here is the professionals list */}
//           <div
//             className="h-[56vh] md:h-[52vh] lg:h-[45vh] w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-6 px-6 md:px-12 py-8 overflow-y-auto"
//             id="proffessionals"
//           >
//             {employees &&
//               employees?.map((employee, i) => (
//                 <div
//                   onClick={() => {
//                     setSelectedEmployeeId(employee?.user_id);
//                   }}
//                   key={i}
//                   className={`${
//                     selectedEmployeeId === employee?.user_id
//                       ? "border-2 border-main"
//                       : ""
//                   } cursor-pointer rounded-3xl bg-white shadow-md shadow-slate-100 h-fit`}
//                 >
//                   <div className="w-full  h-[250px]">
//                     <img
//                       src={employee?.profile_url}
//                       alt={employee?.user_name}
//                       className="w-full h-full object-cover rounded-t-3xl"
//                     />
//                   </div>
//                   <div className="p-4 space-y-2">
//                     <div className="flex items-center gap-3">
//                       <p className="flex items-center gap-1 text-main font-semibold text-2xl">
//                         <img
//                           src="../images/star.png"
//                           alt="star"
//                           className="w-4 h-4 md:w-5 md:h-5 lg:w-7 lg:h-7"
//                         />
//                         {employee?.rate}
//                       </p>
//                       <p className="text-gray-400 text-2xl">
//                         ({employee?.review})
//                       </p>
//                     </div>
//                     <p className=" font-semibold text-3xl">
//                       {employee?.user_name}
//                     </p>
//                   </div>
//                 </div>
//               ))}
//           </div>
//           <div className="px-6 md:px-12 py-2">
//             <p
//               onClick={() => handleSelectedProfessional(selectedEmployeeId)}
//               className="bg-main text-white text-center py-4 rounded-2xl"
//             >
//               Confirm
//             </p>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }