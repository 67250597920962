//
//
//
import { configureStore } from "@reduxjs/toolkit";
import { productsReducer } from "./productsSlice";
import { servicesReducer } from "./servicesSlice";
import { authReducer } from "./authSlice";
import { addToCartReducer } from "./addToCartSlice";
import { getFromCartReducer } from "./getFromCartSlice";
import { getEmploeesReducer } from "./emploeesSlice";
import { codeVerifyReducer } from "./codeVerifySlice";
import { sendCodeReducer } from "./sendCodeSlice";

export const Store = configureStore({
  reducer: {
    auth: authReducer,
    sendCode: sendCodeReducer,
    codeVerify: codeVerifyReducer,
    services: servicesReducer,
    products: productsReducer,
    addToCart: addToCartReducer,
    getFromCart: getFromCartReducer,
    getEmploees: getEmploeesReducer,
  },

  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
