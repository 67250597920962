// //
// //
// import { useEffect, useState } from "react";
// import { GoPlus } from "react-icons/go";
// import { useDispatch, useSelector } from "react-redux";
// import { addToCart } from "../../redux/addToCartSlice";
// import { getDataCart } from "../../redux/getFromCartSlice";
// import { getProductsData } from "../../redux/productsSlice";
// // import { IoMdCheckmark } from "react-icons/io";
// import { toast, Bounce } from "react-toastify";

// export default function Products() {
//   let dispatch = useDispatch();
//   const [cartItems, setCartItems] = useState({
//     product_id: [],
//     item_qty: [],
//   });
//   //
//   let ProductsData = useSelector((state) => state?.products?.products?.data);
//   const products = ProductsData?.data;
//   const currentPage = ProductsData?.current_page;
//   const totalPages = ProductsData?.total;
//   const itemsPerPage = ProductsData?.per_page;
//   const pageNumber = ProductsData?.last_page;
//   // console.log("products",products);

//   //
//   const productsCart = useSelector(
//     (state) => state?.getFromCart?.getCartItems?.data?.details
//   );
//   console.log("products", productsCart);
//   //
//   useEffect(() => {
//     dispatch(getProductsData());
//     dispatch(getDataCart());
//   }, [dispatch]);
//   //
//   // إضافة المنتج إلى السلة
//   const handleAddToCart = (item) => {
//     const isProductInCart = cartItems.product_id.includes(item?.id);

//     if (isProductInCart) {
//       const updatedQty = [...cartItems.item_qty];
//       updatedQty[cartItems.product_id.indexOf(item?.id)] += 1;
//       setCartItems({
//         ...cartItems,
//         item_qty: updatedQty,
//       });
//     } else {
//       setCartItems({
//         product_id: [...cartItems.product_id, item?.id],
//         item_qty: [...cartItems.item_qty, 1],
//       });
//       notify();
//     }
//   };

//   useEffect(() => {
//     if (cartItems?.product_id?.length > 0) {
//       const value = {
//         user_id: localStorage.getItem("user_id"),
//         product_id: cartItems.product_id,
//         item_qty: cartItems.item_qty,
//       };
//       dispatch(addToCart(value));
//     }
//   }, [cartItems, dispatch]);

//   const handlePrevPage = (page) => {
//     const condition = page > 1;
//     if (condition) {
//       page -= 1;
//       dispatch(getProductsData(page));
//     }
//   };
//   const handleNextPage = (page) => {
//     const condition = page < pageNumber;
//     if (condition) {
//       page += 1;
//       dispatch(getProductsData(page));
//     }
//   };
//   const notify = () =>
//     toast("Added successfully", {
//       style: {
//         background: "#A4907C",
//         color: "#fff",
//         fontWeight: "bold",
//         fontSize: "1.2rem",
//         padding: "1rem",
//       },
//       position: "top-right",
//       autoClose: 3000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: 0,
//       theme: "colored",
//       transition: Bounce,
//     });
//   return (
//     <>
//       <div className="h-full pt-4 flex flex-col items-center justify-between">
//         <div className="overflow-y-auto w-full h-[49vh] md:h-[39vh] lg:h-[31vh] grid items-center justify-center grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 py-4 mx-auto md:py-6">
//           {products &&
//             products?.map((item, i) => (
//               <div key={i} className="relative">
//                 <div
//                   className="absolute flex items-center justify-center top-[16px] rtl:!right-auto rtl:left-[22px] right-[22px] z-10 md:cursor-pointer bg-main rounded-full w-[60px] h-[60px] md:w-[70px] md:h-[70px] lg:w-[80px] lg:h-[80px]"
//                   onClick={() => handleAddToCart(item)}
//                 >
//                   {/* {cartItems?.product_id?.includes(item?.id) ? (
//                     <IoMdCheckmark className="text-white w-[36px] h-[36px] md:w-[46px] md:h-[46px] lg:w-[56px] lg:h-[56px]" />
//                     ) : ( */}
//                   <GoPlus className="text-white w-[36px] h-[36px] md:w-[46px] md:h-[46px] lg:w-[56px] lg:h-[56px]" />
//                   {/* )} */}
//                 </div>
//                 <div className="bg-slate-200 w-full rounded-3xl">
//                   <img
//                     className="h-[250px] sm:h-[270px] object-cover w-full object-center rounded-3xl"
//                     src={item.image}
//                     alt={item.name}
//                   />
//                 </div>
//                 <div className="my-4">
//                   <div className="flex items-center gap-1">
//                     <span className="flex items-center">
//                       {Array.from({ length: 5 }, (_, index) => (
//                         <svg
//                           key={index}
//                           className={`w-5 h-5 ${
//                             index < Math.floor(item.rate)
//                               ? "text-[#FD7E14]"
//                               : "text-gray-300"
//                           }`}
//                           fill="currentColor"
//                           viewBox="0 0 20 20"
//                         >
//                           <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.95a1 1 0 00.95.69h4.161c.969 0 1.371 1.24.588 1.81l-3.371 2.449a1 1 0 00-.364 1.118l1.287 3.951c.3.921-.755 1.688-1.54 1.118l-3.371-2.448a1 1 0 00-1.176 0l-3.37 2.448c-.785.57-1.84-.197-1.54-1.118l1.287-3.951a1 1 0 00-.364-1.118L2.784 9.377c-.783-.57-.38-1.81.588-1.81h4.161a1 1 0 00.95-.69l1.286-3.95z" />
//                         </svg>
//                       ))}
//                     </span>
//                     <span className=" font-Inter text-reviewText text-[15px]">
//                       {item.rate} ({item.view} review)
//                     </span>
//                   </div>
//                   <h3 className="text-[18px] font-semibold font-Raleway">
//                     {item?.descriptions[0]?.name || "No Name"}
//                   </h3>
//                   <p className="text-[15px] font-semibold font-Inter flex items-center gap-2">
//                     SAR {item.customer_price}
//                     {item.discount && (
//                       <del className="font-normal text-discountText">
//                         SAR {item.discount}
//                       </del>
//                     )}
//                   </p>
//                 </div>
//               </div>
//             ))}
//         </div>
//         {/* Pagination  */}
//         {products && totalPages > itemsPerPage && (
//           <div className="flex justify-center gap-4 pb-6">
//             <button
//               onClick={() => handlePrevPage(currentPage)}
//               disabled={currentPage === 1}
//               className={`px-4 py-2 rounded ${
//                 currentPage !== 1
//                   ? "bg-main text-white font-semibold"
//                   : "bg-secondary text-white"
//               }`}
//             >
//               Previous
//             </button>
//             <button
//               onClick={() => handleNextPage(currentPage)}
//               disabled={currentPage >= pageNumber}
//               className={`px-4 py-2 rounded ${
//                 currentPage == pageNumber
//                   ? "bg-secondary text-white"
//                   : "bg-main text-white font-semibold"
//               }`}
//             >
//               Next
//             </button>
//           </div>
//         )}
//       </div>
//     </>
//   );
// }



import { useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { getProductsData } from "../../redux/productsSlice";
import { toast, Bounce } from "react-toastify";
import LogoutBtn from "../../components/logoutBtn";

export default function Products() {
  const dispatch = useDispatch();
  //
  const ProductsData = useSelector((state) => state?.products?.products?.data);
  const products = ProductsData?.data;
  const currentPage = ProductsData?.current_page;
  const totalPages = ProductsData?.total;
  const itemsPerPage = ProductsData?.per_page;
  const pageNumber = ProductsData?.last_page;
  // console.log("products", products);
  //
  useEffect(() => {
    dispatch(getProductsData());
  }, [dispatch]);
  //
  const [cartItems, setCartItems] = useState({
    product: [],
    product_qty: [],
    professionals: [],
  });
  //
  useEffect(() => {
    const storedItems = JSON.parse(sessionStorage.getItem("cartItems")) || {
      product: [],
      product_qty: [],
      professionals: [],
    };
    if (storedItems) {
      setCartItems(storedItems);
    }
  }, []);
  // This function is called when the user clicks the "Add to Cart" button
  // It takes the item that is being added to the cart as an argument
  // The function then updates the state of the cart by adding the new item
  // or incrementing the quantity of an existing item
  //
  const handleAddToCart = (item) => {
    // Make a copy of the current products in the cart
    let updatedProducts = [...cartItems.product];
    // Make a copy of the current quantities in the cart
    let updatedQuantities = [...cartItems.product_qty];
    // Make a copy of the current professionals in the cart
    let updatedProfessionals = [...cartItems.professionals];
    //
    // Check if the item is already in the cart
    const existingProductIndex = updatedProducts.findIndex(
      (cartProduct) => cartProduct.id === item.id
    );
    //
    // If the item is already in the cart, increment its quantity by 1
    if (existingProductIndex >= 0) {
      updatedQuantities[existingProductIndex] += 1;  
    } 
    // If the item is not in the cart, add it to the cart with a quantity of 1
    else {
      updatedProducts.push(item); 
      updatedQuantities.push(1);  
      updatedProfessionals.push(null);
    }
    //
    // Create a new object with the updated products and quantities
    const updatedCartItems = {
      product: updatedProducts,
      product_qty: updatedQuantities,
      professionals: updatedProfessionals,
    };
    //
    // Update the state of the cart with the new object
    setCartItems(updatedCartItems);
    // Save the updated cart to local storage
    sessionStorage.setItem("cartItems", JSON.stringify(updatedCartItems)); 
    //
    // Show a notification to the user that the item has been added to the cart
    notify();  
  };
  //
  const handlePrevPage = (page) => {
    if (page > 1) {
      page -= 1;
      dispatch(getProductsData(page));
    }
  };
  //
  // This function takes the current page number as an argument
  // and checks if the page number is less than the total number of pages
  // If it is, it increments the page number by 1 and
  // dispatches an action to the Redux store to get the next page of data
  const handleNextPage = (page) => {
    // Check if the page number is less than the total number of pages
    if (page < pageNumber) {
      // Increment the page number by 1
      page += 1;
      // Dispatch an action to the Redux store to get the next page of data
      dispatch(getProductsData(page));
    }
  };
  //
  const notify = () =>
    toast("Added successfully", {
      style: {
        background: "#A4907C",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "1.2rem",
        padding: "1rem",
      },
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "colored",
      transition: Bounce,
    }
  );

  return (
    <>
      <LogoutBtn />
      <div className="h-full pt-4 flex flex-col items-center justify-between">
        <div className="overflow-y-auto w-full min-h-[49vh] md:min-h-[39vh] lg:min-h-[31vh] grid items-center justify-center grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 py-4 mx-auto md:py-6">
          {products &&
            products.map((item, i) => (
              <div key={i} className="relative">
                <div
                  className="absolute flex items-center justify-center top-[16px] rtl:!right-auto rtl:left-[22px] right-[22px] z-10 md:cursor-pointer bg-main rounded-full w-[60px] h-[60px] md:w-[70px] md:h-[70px] lg:w-[80px] lg:h-[80px]"
                  onClick={() => handleAddToCart(item)}
                >
                  <GoPlus className="text-white w-[36px] h-[36px] md:w-[46px] md:h-[46px] lg:w-[56px] lg:h-[56px]" />
                </div>
                <div className="bg-slate-200 w-full rounded-3xl">
                  <img
                    className="h-[250px] sm:h-[270px] object-cover w-full object-center rounded-3xl"
                    src={item.image}
                    alt={item.name}
                  />
                </div>
                <div className="my-4">
                  <div className="flex items-center gap-1">
                    <span className="flex items-center">
                      {Array.from({ length: 5 }, (_, index) => (
                        <svg
                          key={index}
                          className={`w-5 h-5 ${
                            index < Math.floor(item.rate)
                              ? "text-[#FD7E14]"
                              : "text-gray-300"
                          }`}
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.95a1 1 0 00.95.69h4.161c.969 0 1.371 1.24.588 1.81l-3.371 2.449a1 1 0 00-.364 1.118l1.287 3.951c.3.921-.755 1.688-1.54 1.118l-3.371-2.448a1 1 0 00-1.176 0l-3.37 2.448c-.785.57-1.84-.197-1.54-1.118l1.287-3.951a1 1 0 00-.364-1.118L2.784 9.377c-.783-.57-.38-1.81.588-1.81h4.161a1 1 0 00.95-.69l1.286-3.95z" />
                        </svg>
                      ))}
                    </span>
                    <span className="font-Inter text-reviewText text-[15px]">
                      {item.rate} ({item.view} review)
                    </span>
                  </div>
                  <h3 className="text-[18px] font-semibold font-Raleway">
                    {item?.descriptions[0]?.name || "No Name"}
                  </h3>
                  <p className="text-[15px] font-semibold font-Inter flex items-center gap-2">
                    SAR {item.customer_price}
                    {item.discount && (
                      <del className="font-normal text-discountText">
                        SAR {item.discount}
                      </del>
                    )}
                  </p>
                </div>
              </div>
            ))}
        </div>
        {/* Pagination  */}
        {products && totalPages > itemsPerPage && (
          <div className="flex justify-center gap-4 pb-6">
            <button
              onClick={() => handlePrevPage(currentPage)}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded ${
                currentPage !== 1
                  ? "bg-main text-white font-semibold"
                  : "bg-secondary text-white"
              }`}
            >
              Previous
            </button>
            <button
              onClick={() => handleNextPage(currentPage)}
              disabled={currentPage >= pageNumber}
              className={`px-4 py-2 rounded ${
                currentPage == pageNumber
                  ? "bg-secondary text-white"
                  : "bg-main text-white font-semibold"
              }`}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </>
  );
}

