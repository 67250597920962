



import React, { useEffect, useState } from "react";
import Logo from "../icons/logo";
import PhoneIcon from "../icons/phoneIcon";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { FaRegKeyboard } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getCodeData } from "../redux/sendCodeSlice";

export default function PhoneVerification() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isShowKeyboard, setIsShowKeyboard] = useState(false);
  

  const codeData = useSelector((state) => state?.sendCode?.code);
  const codeMessage = codeData?.message;
  // console.log("codeData", codeData);


    useEffect(() => {
      dispatch(getCodeData());

    }, [dispatch]);

  const validationForm = yup.object({
    country: yup.string().required(`${t("Country is required")}`),
    customer_phone: yup
      .string()
      .required(`${t("Number is required")}`)
      .test("valid-phone", "Number is not valid", function (value) {
        const { country } = this.parent;
        // الجزء ده لازم اعدله
        if (value && value.length == 7) {
          value = value + "00"; 
        }
        console.log(value);
        
        const phoneNumber = parsePhoneNumberFromString(
          value,
          country === "KSA" ? "SA" : "EG"
        );
        return phoneNumber ? phoneNumber.isValid() : false;
      }),
  });

  const formik = useFormik({
    initialValues: {
      country: "KSA",
      customer_phone: "",
    },
    onSubmit: (values, { resetForm }) => {
      console.log(values.customer_phone);
      
      if (formik.isValid) {
        const cuntryCode = values.country === "KSA" ? "+966" : "+20";
        const phone = cuntryCode + values.customer_phone;
        sessionStorage.setItem("phone", phone);
        dispatch(getCodeData());
        setTimeout(() => {
          if (codeData ) {
            navigate("/codeVerification");
          }
        },2000)
        resetForm();
      } else {
        console.log("Invalid form");
      }
    },
    validationSchema: validationForm,
  });

  const [phoneInput, setPhoneInput] = useState("");


  const handleKeyPress = (value) => {
    if (value === "delete") {
      setPhoneInput(phoneInput.slice(0, -1));
    } else {
      setPhoneInput(phoneInput + value);
    }
    formik.setFieldValue("customer_phone", phoneInput + value);
  };      
  const handleKeyDown = (e) => {
    if (e.key >= "0" && e.key <= "9") {
      setPhoneInput((prev) => prev + e.key);
      formik.setFieldValue("customer_phone", phoneInput + e.key);
    } else if (e.key === "Backspace") {
      setPhoneInput((prev) => prev.slice(0, -1));
      formik.setFieldValue("customer_phone", phoneInput.slice(0, -1));
    }
  };

  return (
    <article className="bg-verification flex flex-col justify-center h-full">
      <div className="mx-auto w-full">
        <div className="mx-auto w-fit">
          <Logo
            className={
              "w-[250px] h-[350px] md:h-[350px] md:w-[450px] lg:h-[460px] lg:w-[636.32px]"
            }
          />
        </div>
        <div className="mx-auto w-full">
          <h1 className="text-center text-textBold text-[28px] md:text-[76px] lg:text-[92.16px] font-medium">
            Phone Verification
          </h1>
          <form className="px-[47px]" onSubmit={formik.handleSubmit}>
            <p className="text-[#5B5957] text-[16px] md:text-[32px] lg:text-[40.32px] ">
              Phone Number
            </p>
            <div className="flex items-center border border-[#E5E5E5] gap-4 rounded-[15px] md:rounded-[20px] lg:rounded-[30px] ps-6">
              <PhoneIcon className="w-14 h-14" />
              <div>
                <select
                  name="country"
                  className="block w-[80px] md:w-[100px] lg:w-[140px] text-[20.32px] md:text-[30.32px] lg:text-[40.32px] focus:outline-none bg-transparent"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.country}
                >
                  <option value="KSA">KSA</option>
                  <option value="EGY">EGY</option>
                </select>
              </div>
              <div className="w-full">
                <input
                  type="tel"
                  name="customer_phone"
                  aria-autocomplete="off"
                  value={phoneInput}
                  placeholder="Enter your phone number"
                  className="w-full text-[20.32px] md:text-[30.32px] lg:text-[40.32px] border-none focus:outline-none rounded-md py-6 bg-transparent "
                  readOnly
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
            {formik.errors.customer_phone && formik.touched.customer_phone ? (
              <span className="text-red-500 text-[16.32px] md:text-[22.32px] lg:text-[30.32px]">
                * {formik.errors.customer_phone}{" "}
              </span>
            ) : null}
            {codeMessage == "Enter customer phone" &&
            formik.errors.customer_phone &&
            formik.touched.customer_phone ? (
              <span className="text-red-500 text-[16.32px] md:text-[22.32px] lg:text-[30.32px]">
                ( {codeMessage} )
              </span>
            ) : null}
            <button
              type="submit"
              className="mainButton w-full py-4 my-4 font-bold text-white rounded-[30px] lg:text-[40.32px]"
            >
              Verify
            </button>
          </form>
          <div
            onClick={() => setIsShowKeyboard(!isShowKeyboard)}
            className="cursor-pointer mx-auto w-fit"
          >
            <FaRegKeyboard className="w-[30px] h-[30px] md:w-[40px] md:h-[40px] lg:w-[50px] lg:h-[50px]" />
          </div>
          {/* keyboard */}
          <div
            className={`${
              isShowKeyboard ? "" : "hidden"
            } md:px-[37px] lg:px-[47px] grid grid-cols-3 gap-4 my-6 w-full mx-auto`}
          >
            {["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].map((num) => (
              <button
                key={num}
                onClick={() => handleKeyPress(num)} // ربط الإدخال بأزرار الكيبورد
                className="p-4 bg-gray-300 text-[30px] rounded-lg"
              >
                {num}
              </button>
            ))}
            <button
              onClick={() => handleKeyPress("delete")}
              className="col-span-2 p-4 bg-main text-white text-[30px] rounded-lg"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </article>
  );
}

