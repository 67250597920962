//
//
//

//
//
//

import * as yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FaEye, FaEyeSlash, FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getLoginData } from "../redux/authSlice";
import { useNavigate } from 'react-router-dom';
import { toast, Bounce } from "react-toastify";


export default function LogIn() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = (type) => {
    switch (type) {
      case "show":
        setShowPassword(!showPassword);
    }
  };
  //
  const logInData = useSelector((state) => state?.auth?.user);
  const logInStatus = logInData?.status;
  // console.log("logInData",logInData);
    
    useEffect(() => {
        dispatch(getLoginData());
    }, [dispatch]);

    useEffect(() => {
      if (logInStatus == true) {
        notify()
        setTimeout(() => {
            navigate("/");
        },2000)
      } else if (logInStatus === false) {
          navigate("/login");
      }
    }, [logInStatus]);
    
  //
  const validationForm = yup.object({
    user_name: yup.string().required("User Name is required"),
    password: yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      user_name: "",
      password: "",
    },
      onSubmit: (values) => {
          console.log(values);
          dispatch(getLoginData(values));
      },
    validationSchema: validationForm,
  });
  
    const notify = () =>
        toast("Logged in successfully", {
        style: {
        background: "#A4907C",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "1.2rem",
        padding: "1rem",
        },
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
        transition: Bounce,
        }
    );  
  return (
    <>
      <section className="h-[100vh] flex justify-center items-center ">
        <div className=" p-6 rounded-xl shadow-xl w-[80vw] md:w-[60vw] lg:w-[40vw] mx-auto">
          <h2 className="text-2xl md:text-3xl lg:text-4xl mb-6 flex items-center gap-2 text-main">
            <FaUserCircle />
            <span className="capitalize">sign in</span>
          </h2>
          <form onSubmit={formik.handleSubmit} className="">
            <div className="mb-3 border border-[#E5E5E5] gap-4 rounded-[15px] md:rounded-[20px] lg:rounded-[30px] ">
              <input
                type="text"
                aria-autocomplete="off"
                placeholder="User Name"
                className="w-full text-[16px] md:text-[18px] lg:text-[20px] text-gray-500 border-none focus:outline-none rounded-md py-2 px-4 bg-transparent"
                name="user_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.user_name}
              />
            </div>
            {formik.errors.user_name && formik.touched.user_name ? (
              <span className="text-red-500">* {formik.errors.user_name}</span>
            ) : (
              ""
            )}
            <div className="">
              <div className="relative border border-[#E5E5E5] gap-4 rounded-[15px] md:rounded-[20px] lg:rounded-[30px] ">
                <input
                  type={showPassword ? "text" : "password"}
                  aria-autocomplete="off"
                  placeholder="Password"
                  className="w-full text-[16px] md:text-[18px] lg:text-[20px] text-gray-500 border-none focus:outline-none rounded-md py-2 px-4 bg-transparent"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility("show")}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                >
                  {showPassword ? (
                    <FaEyeSlash className="h-6 w-6 md:h-7 md:w-7 lg:h-8 lg:w-8 text-gray-600" />
                  ) : (
                    <FaEye className="h-6 w-6 md:h-7 md:w-7 lg:h-8 lg:w-8 text-gray-600" />
                  )}
                </button>
              </div>
              {formik.errors.password && formik.touched.password ? (
                <span className="text-red-500">* {formik.errors.password}</span>
              ) : (
                ""
              )}
              {logInData?.message == "incorrect user name or password" && (
                <span className="text-red-500">* {logInData?.message}</span>
              )}
            </div>
            <button
              type="submit"
              className="mainButton w-full py-2 mt-12 font-semibold text-white rounded-[30px] text-[18px] md:text-[22px] lg:text-[26px]"
            >
              Sign In
            </button>
          </form>
        </div>
      </section>
    </>
  );
}
