// //
// //
// //
// import axios from "axios";
// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// //

// export const addToCart = createAsyncThunk("addToCart/addToCart", async (values) => {

//   const options = {
//     url: "https://testecommerce.vodoglobal.com/api/v2/abandoned_order",
//     method: "POST",
//     headers: {
//       lang: "en",
//       "secure-business-key": "3717466d81c36d98ac4.30464285",
//     },
//     data: values,
//   };

//   try {
//     const { data } = await axios.request(options);
//     console.log(data);
//     return data;
//   } catch (error) {
//     // console.log(error);
//     return error;
//   }
// });
// export const changeQty = createAsyncThunk("addToCart/changeQty", async (props) => {
//   const options = {
//     url: `https://testecommerce.vodoglobal.com/api/v2/change_item_qty_or_remove/${props.id}`,
//     method: "PUT",
//     headers: {
//       "secure-business-key": "728106399db2b289783.89154521",
//       status: props.state,
//     },
//   };

//   try {
//     const { data } = await axios.request(options);
//     // console.log(data);
//     return data;
//   } catch (error) {
//     // console.log(error);
//     return error;
//   }
// });

// const initialValues = {
//   addItems: null,
//   isLoading: false,
//   isError: null,
// };

// const addToCartSlice = createSlice({
//   name: "addToCart",
//   initialState: initialValues,
//   extraReducers: (builder) => {
//     builder
//       .addCase(addToCart.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.addItems = action.payload;
//         // console.log(action.payload);
//       })
//       .addCase(addToCart.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(addToCart.rejected, (state, action) => {
//         state.isError = action.error;
//         state.isLoading = false;
//         // console.log(action.error);
//       });
//   },
//   reducers: {},
// });

// export const addToCartReducer = addToCartSlice.reducer;




import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// // جلب البيانات من sessionStorage
// const getCartItemsFromSessionStorage = () => {
//   const cartItems = sessionStorage.getItem("cartItems");
//   return cartItems ? JSON.parse(cartItems) : { product_id: [], item_qty: [] };
// };

// إضافة المنتجات إلى السلة
export const addToCart = createAsyncThunk("addToCart/addToCart", async () => {
  // // جلب البيانات من sessionStorage
  // const { product_id, item_qty } = getCartItemsFromSessionStorage();

  // // إعداد البيانات للإرسال
  // const values = product_id.map((id, index) => ({
  //   product_id: id,
  //   item_qty: item_qty[index],
  // }));
  const cartItems = sessionStorage.getItem("cartItems");
  const valuse = cartItems
    ? JSON.parse(cartItems)
    : { product_id: [], item_qty: [] };
  console.log(valuse);
  
  const options = {
    url: "https://testecommerce.vodoglobal.com/api/v2/abandoned_order",
    method: "POST",
    headers: {
      lang: "en",
      "secure-business-key": "4765066450c0bd66325.48403130",
    },
    data: valuse,
  };

  try {
    const { data } = await axios.request(options);
    console.log(data);
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
});

export const changeQty = createAsyncThunk("addToCart/changeQty", async (props) => {
  const options = {
    url: `https://testecommerce.vodoglobal.com/api/v2/change_item_qty_or_remove/${props.id}`,
    method: "PUT",
    headers: {
      "secure-business-key": "728106399db2b289783.89154521",
      status: props.state,
    },
  };

  try {
    const { data } = await axios.request(options);
    // console.log(data);
    return data;
  } catch (error) {
    // console.log(error);
    return error;
  }
});


const initialValues = {
  addItems: null,
  isLoading: false,
  isError: null,
};

const addToCartSlice = createSlice({
  name: "addToCart",
  initialState: initialValues,
  extraReducers: (builder) => {
    builder
      .addCase(addToCart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.addItems = action.payload;
      })
      .addCase(addToCart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.isError = action.error;
        state.isLoading = false;
      });
  },
});

export const addToCartReducer = addToCartSlice.reducer;
