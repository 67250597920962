//
//
//
import { useEffect } from "react";
import Logo from "../icons/logo";
// import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

export default function Welcome() {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    const token = sessionStorage.getItem("token")
      if (!token) {
        navigate("/login");

      }
  }, [navigate]);
  return (
    <section className="relative bg-slate-300">
      <img
        src="./images/welcome.png"
        className="absolute top-0 left-0 w-full h-full z-0 object-cover"
      />
      <img
        src="/images/top-Line.png"
        className="absolute top-0 left-0 w-full h-full z-10"
      />
      <img
        src="/images/bttom-Lines.png"
        className="absolute bottom-0 left-0 w-full h-fullz-10"
      />
      <div className="relative flex flex-col justify-center w-full min-h-screen mx-auto px-8 md:px-12 lg:px-16 z-20 text-white ">
        <div className="mx-auto w-fit">
          <Logo
            className={
              "w-[320px] h-[280px] md:w-[450px] md:h-[350px] lg:w-[570px] lg:h-[410px] "
            }
          />
        </div>
        <h1 className="text-[40px] md:text-[70px] lg:text-[100px] text-center mx-auto">
          {/* {t("welcome")} */}
          Welcome to
          <span className="font-black">
            {/* {t("layers")} */}
            Layers
          </span>
        </h1>
        <p className="w-full px-10 md:px-16 lg:px-32 text-center mx-auto  font-light text-[25px]  md:text-[34px] lg:text-[48px] leading-[30px] md:leading-[40px] lg:leading-[56.35px]">
          {/* {t("unwind")} */}
          Unwind and embrace your unique beauty with our personalized treatments
          and exceptional service.
        </p>
        <Link to={"/home"}>
          <button className="w-full mx-auto my-6 block hover:bg-[#ffffff40] active:bg-[#ffffff29] capitalize  py-3 md:py-6 rounded-[15px] md:rounded-[25px] text-[20px] md:text-[28px] lg:text-[40px] bg-[#ffffff29] text-white font-semibold">
            {/* {t("start")} */}
            get started
          </button>
        </Link>
      </div>
    </section>
  );
};


