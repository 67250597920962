//
//
//
import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//
export const getLoginData = createAsyncThunk("auth/loginData", async (values) => {
  const options = {
    url: "https://testaccounts.vodoerp.com/api/login",
    method: "POST",
    data: values,
  };

  try {
    const { data } = await axios.request(options);
    // console.log(data?.data);
    return data;
  } catch (error) {
    // console.log(error);
    return error;
  }
});

const initialValues = {
  user:null,
  isLoading: false,
  isError: null,
};


const authSlice = createSlice({
  name: "auth",
  initialState: initialValues,
  extraReducers: (builder) => {
    builder
      .addCase(getLoginData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        sessionStorage.setItem(
          "token",
          action.payload.data?.personal_access_token
        );
        sessionStorage.setItem("brunch_id", action.payload.data.user_branch);
      })
      .addCase(getLoginData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLoginData.rejected, (state, action) => {
        state.isError = action.error;
        state.isLoading = false;
        // console.log(action.error);
      })

  },
  reducers: {},
});

export const authReducer = authSlice.reducer;
