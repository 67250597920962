import CartIcon from "../icons/cartIcon";
import Logo from "../icons/logo";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
// ===== this for lang=====
// import { useState } from "react";
// import { useEffect} from "react";
// import Select from "./select";
// import { useOptions } from "../hooks/useOptions";
// import i18n from "../i18n";
// const enFlag = "../images/en.png";
// const arFlag = "../images/ar.png";
const Header = () => {
  const { t } = useTranslation();
  // const [lang, setLang] = useState("");
  // const { languageOptions } = useOptions();
  // //
  // useEffect(() => {
  //   i18n.changeLanguage(lang);
  //   document.dir = lang === "ar" ? "rtl" : "ltr";
  //   setLang(i18n.language);
  // }, [lang]);
  // const onLangChange = (value) => {
  //   setLang(value);
  // };
  //
  return (
    <header className="container-box py-[10px] md:py-[16px] lg:py-[24px]">
      <nav className="flex items-center justify-between">
        <NavLink to="/home">
          <Logo
            fill={"black"}
            className="w-[140px] h-[140px] md:w-[170px] md:h-[170px] lg:w-[280px] lg:h-[200px]"
          />
        </NavLink>
        <div className="flex items-center gap-6">
          <NavLink
            to="/cart"
            className={({ isActive }) => {
              return `bg-secondary px-4 py-2
                  ${
                    isActive ? "bg-secondary px-4 py-2 rounded-3xl" : "bg-white"
                  }
                  `;
            }}
          >
            <div>
              <CartIcon className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] lg:w-[60px] lg:h-[60px]" />
              <p className="py-1 text-center ">{t("cart")}</p>
            </div>
          </NavLink>
          {/* 
          <hr className="border h-[30px] rounded-full" />
          <label htmlFor="language">
            <img
              className="size-[30px] md:size-[45px] lg:size-[60px] rounded-full object-cover bg-center mb-2 mx-auto"
              src={lang === "en-US" ? enFlag : arFlag}
              alt="flag"
            />
            <Select
              items={languageOptions}
              onChange={(e) => onLangChange(e)}
              placeholder={lang === "en-US" ? "EN" : "AR"}
              className={"border-none"}
              id="language"
            />
          </label>
          */}
        </div>
      </nav>
    </header>
  );
};

export default Header;
