const CameraCaptureIcon = ({ className, fill }) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.395 12.3482C15.9991 11.8274 18.2896 10.2934 19.7627 8.08381L19.8475 7.95664C21.773 5.06835 25.0146 3.33349 28.4859 3.33349L35.514 3.33349C38.9852 3.33349 42.2269 5.06835 44.1524 7.95664L44.2372 8.08381C45.7103 10.2934 48.0008 11.8274 50.6049 12.3482V12.3482C56.4553 13.5183 60.6666 18.6552 60.6666 24.6215L60.6666 39.3335C60.6666 46.8009 60.6666 50.5345 59.2133 53.3867C57.935 55.8955 55.8953 57.9353 53.3865 59.2136C50.5343 60.6668 46.8006 60.6668 39.3333 60.6668L24.6666 60.6668C17.1992 60.6668 13.4655 60.6668 10.6134 59.2136C8.10456 57.9353 6.06482 55.8955 4.78651 53.3867C3.33326 50.5345 3.33326 46.8009 3.33326 39.3335L3.33326 24.6215C3.33326 18.6552 7.54452 13.5183 13.395 12.3482V12.3482ZM31.9999 45.3335C37.891 45.3335 42.6666 40.5579 42.6666 34.6668C42.6666 28.7758 37.891 24.0002 31.9999 24.0002C26.1089 24.0002 21.3333 28.7758 21.3333 34.6668C21.3333 40.5579 26.1089 45.3335 31.9999 45.3335Z"
        fill={fill || "white"}
      />
    </svg>
  );
};

export default CameraCaptureIcon;
