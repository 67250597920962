//
//
//
import { Suspense } from "react";
import Cart from "../src/pages/cart";
import AppLayout from "../src/layout";
import Home from "../src/pages/home/home";
import Services from "../src/pages/home/service";
import Products from "./pages/home/products";
import Camera from "../src/pages/home/camera";
import Welcome from "../src/pages/welcome";
import CodeVerification from "../src/pages/codeVerification";
import PhoneVerification from "../src/pages/phoneVerification";
import DoneOrder from "./pages/doneOrder";
//
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
//
import { ToastContainer } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
import LogIn from "./pages/logIn";
function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route element={<AppLayout />}>
            <Route path="/" element={<Welcome />} />
            <Route path="home" element={<Home />}>
              <Route index element={<Services />} />
              <Route path="camera" element={<Camera />} />
              <Route path="products" element={<Products />} />
            </Route>
            <Route path="cart" element={<Cart />} />
            <Route path="phoneVerification" element={<PhoneVerification />} />
            <Route path="codeVerification" element={<CodeVerification />} />
            <Route path="doneOrder" element={<DoneOrder />} />
            <Route path="login" element={<LogIn />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
