//
//
//
import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";

export default function Home() {
  const { t } = useTranslation();
  return (
    <section className="container-box w-full h-full flex flex-col">
      <nav className="w-full py-4">
        <ul className="flex items-center justify-between">
          <NavLink
            to="/home"
            end
            className={({ isActive }) => {
              return `
                rounded-3xl py-2 px-5 sm:py-6 sm:px-10 md:px-12 lg:px-16
              ${
                isActive
                  ? "bg-secondary text-textBold font-bold active-link"
                  : "text-gray-600 font-bold inactive-link"
              }
              `;
            }}
          >
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mx-auto w-8 h-8 sm:w-12 sm:h-12 md:w-14 md:h-14 lg:w-16 lg:h-16"
            >
              <path
                opacity="0.12"
                d="M24.3676 12.5982C26.8241 7.68506 28.0524 5.22849 29.7146 4.44018C31.161 3.75417 32.8391 3.75417 34.2855 4.44018C35.9476 5.2285 37.1759 7.68507 39.6325 12.5982L40.5905 14.5144C41.349 16.0314 41.7283 16.7899 42.2831 17.3706C42.7743 17.8847 43.3632 18.2954 44.0154 18.5785C44.7521 18.8984 45.595 18.992 47.2806 19.1793L48.1504 19.276C54.039 19.9302 56.9833 20.2574 58.3139 21.6149C59.4702 22.7947 60.0146 24.4437 59.7879 26.08C59.5271 27.9629 57.3562 29.9787 53.0146 34.0103L52.0963 34.8629C50.6143 36.2391 49.8733 36.9272 49.4451 37.761C49.0665 38.498 48.8649 39.3131 48.8562 40.1415C48.8464 41.0788 49.1856 42.0455 49.864 43.9789V43.9789C52.0783 50.2899 53.1855 53.4454 52.4613 55.2902C51.833 56.8903 50.4721 58.0895 48.8056 58.5114C46.8843 58.9977 43.938 57.5245 38.0453 54.5782L35.8162 53.4637C34.417 52.764 33.7173 52.4142 32.9835 52.2765C32.3335 52.1546 31.6665 52.1546 31.0166 52.2765C30.2827 52.4142 29.5831 52.764 28.1838 53.4637L25.9546 54.5782C20.062 57.5245 17.1157 58.9977 15.1944 58.5114C13.528 58.0895 12.167 56.8903 11.5387 55.2902C10.8145 53.4454 11.9217 50.2899 14.136 43.9789V43.9789C14.8144 42.0455 15.1536 41.0788 15.1438 40.1415C15.1351 39.3131 14.9335 38.498 14.555 37.761C14.1267 36.9272 13.3857 36.2391 11.9037 34.8629L10.9854 34.0103C6.64376 29.9787 4.47292 27.9629 4.21208 26.08C3.98541 24.4437 4.52978 22.7947 5.68611 21.6149C7.01672 20.2574 9.96101 19.9302 15.8496 19.276L16.7194 19.1793C18.405 18.992 19.2479 18.8984 19.9846 18.5785C20.6368 18.2954 21.2257 17.8847 21.7169 17.3706C22.2717 16.7899 22.651 16.0314 23.4095 14.5144L24.3676 12.5982Z"
                fill={"#605F5F"}
              />
              <path
                d="M24.3676 12.5982C26.8241 7.68506 28.0524 5.22849 29.7146 4.44018C31.161 3.75417 32.8391 3.75417 34.2855 4.44018C35.9476 5.2285 37.1759 7.68507 39.6325 12.5982L40.5905 14.5144C41.349 16.0314 41.7283 16.7899 42.2831 17.3706C42.7743 17.8847 43.3632 18.2954 44.0154 18.5785C44.7521 18.8984 45.595 18.992 47.2806 19.1793L48.1504 19.276C54.039 19.9302 56.9833 20.2574 58.3139 21.6149C59.4702 22.7947 60.0146 24.4437 59.7879 26.08C59.5271 27.9629 57.3562 29.9787 53.0146 34.0103L52.0963 34.8629C50.6143 36.2391 49.8733 36.9272 49.4451 37.761C49.0665 38.498 48.8649 39.3131 48.8562 40.1415C48.8464 41.0788 49.1856 42.0455 49.864 43.9789V43.9789C52.0783 50.2899 53.1855 53.4454 52.4613 55.2902C51.833 56.8903 50.4721 58.0895 48.8056 58.5114C46.8843 58.9977 43.938 57.5245 38.0453 54.5782L35.8162 53.4637C34.417 52.764 33.7173 52.4142 32.9835 52.2765C32.3335 52.1546 31.6665 52.1546 31.0166 52.2765C30.2827 52.4142 29.5831 52.764 28.1838 53.4637L25.9546 54.5782C20.062 57.5245 17.1157 58.9977 15.1944 58.5114C13.528 58.0895 12.167 56.8903 11.5387 55.2902C10.8145 53.4454 11.9217 50.2899 14.136 43.9789V43.9789C14.8144 42.0455 15.1536 41.0788 15.1438 40.1415C15.1351 39.3131 14.9335 38.498 14.555 37.761C14.1267 36.9272 13.3857 36.2391 11.9037 34.8629L10.9854 34.0103C6.64376 29.9787 4.47292 27.9629 4.21208 26.08C3.98541 24.4437 4.52978 22.7947 5.68611 21.6149C7.01672 20.2574 9.96101 19.9302 15.8496 19.276L16.7194 19.1793C18.405 18.992 19.2479 18.8984 19.9846 18.5785C20.6368 18.2954 21.2257 17.8847 21.7169 17.3706C22.2717 16.7899 22.651 16.0314 23.4095 14.5144L24.3676 12.5982Z"
                stroke
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <li className="capitalize text-[14px] sm:text-xl md:text-2xl lg:text-[32px] mt-4">
              {t("services")}
            </li>
          </NavLink>

          <NavLink
            to="products"
            className={({ isActive }) => `
              rounded-3xl py-2 px-5 sm:py-6 sm:px-10 md:px-12 lg:px-16
              ${
                isActive
                  ? "bg-secondary text-textBold font-bold active-link"
                  : "text-gray-600 font-bold inactive-link"
              }
              `}
          >
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mx-auto w-8 h-8 sm:w-12 sm:h-12 md:w-14 md:h-14 lg:w-16 lg:h-16"
            >
              <g opacity="0.12">
                <path
                  d="M32 53.3333C32 56.2788 29.6122 58.6666 26.6667 58.6666C23.7212 58.6666 21.3333 56.2788 21.3333 53.3333C21.3333 50.3878 23.7212 48 26.6667 48C29.6122 48 32 50.3878 32 53.3333Z"
                  fill={"#605F5F"}
                />
                <path
                  d="M53.3333 53.3333C53.3333 56.2788 50.9455 58.6666 48 58.6666C45.0545 58.6666 42.6667 56.2788 42.6667 53.3333C42.6667 50.3878 45.0545 48 48 48C50.9455 48 53.3333 50.3878 53.3333 53.3333Z"
                  fill={"#605F5F"}
                />
                <path
                  d="M44.5749 10.6668C50.2725 10.6668 53.1213 10.6668 55.0484 11.8513C56.7369 12.8891 57.98 14.5166 58.5369 16.4186C59.1726 18.5894 58.4231 21.3379 56.9239 26.8347L56.7632 27.424C55.5349 31.9279 54.9207 34.1799 53.6283 35.8533C52.4878 37.3301 50.9798 38.4819 49.255 39.1936C47.3005 40.0001 44.9663 40.0001 40.2979 40.0001H34.2068C29.5922 40.0001 27.2849 40.0001 25.3461 39.2083C23.6348 38.5093 22.1345 37.3777 20.9924 35.9242C19.6983 34.2776 19.0645 32.0591 17.7967 27.6221L12.9524 10.6668H44.5749Z"
                  fill={"#605F5F"}
                />
              </g>
              <path
                d="M2.66666 2.66685L3.99999 2.66669V2.66669C4.60227 2.6667 4.90341 2.66671 5.17381 2.68506C8.34093 2.89997 11.0814 4.96714 12.158 7.9534C12.2499 8.20835 12.3327 8.4979 12.4981 9.07701L12.9524 10.6669M12.9524 10.6669L17.7967 27.6221C19.0644 32.0591 19.6983 34.2776 20.9923 35.9243C22.1345 37.3777 23.6348 38.5094 25.3461 39.2083C27.2849 40.0002 29.5922 40.0002 34.2067 40.0002H40.2979C44.9663 40.0002 47.3005 40.0002 49.255 39.1937C50.9798 38.4819 52.4878 37.3301 53.6283 35.8533C54.9207 34.1799 55.5348 31.928 56.7632 27.424L56.9239 26.8348C58.423 21.3379 59.1726 18.5895 58.5369 16.4186C57.98 14.5167 56.7368 12.8891 55.0484 11.8513C53.1213 10.6669 50.2725 10.6669 44.5749 10.6669H12.9524ZM32 53.3334C32 56.2789 29.6122 58.6667 26.6667 58.6667C23.7211 58.6667 21.3333 56.2789 21.3333 53.3334C21.3333 50.3878 23.7211 48 26.6667 48C29.6122 48 32 50.3878 32 53.3334ZM53.3333 53.3334C53.3333 56.2789 50.9455 58.6667 48 58.6667C45.0545 58.6667 42.6667 56.2789 42.6667 53.3334C42.6667 50.3878 45.0545 48 48 48C50.9455 48 53.3333 50.3878 53.3333 53.3334Z"
                stroke
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <li className="capitalize text-[14px] sm:text-xl md:text-2xl lg:text-[32px] mt-4">
              {t("shop")}
            </li>
          </NavLink>

          <NavLink
            to="camera"
            className={({ isActive }) => `
              rounded-3xl py-2 px-5 sm:py-6 sm:px-10 md:px-12 lg:px-16
              ${
                isActive
                  ? "bg-secondary text-textBold font-bold active-link"
                  : "text-gray-600 font-bold inactive-link"
              }
              `}
          >
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mx-auto w-8 h-8 sm:w-12 sm:h-12 md:w-14 md:h-14 lg:w-16 lg:h-16"
            >
              <path
                opacity="0.12"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.9937 9.84335C19.5038 12.0782 17.1872 13.6296 14.5534 14.1564L14.1913 14.2288C9.04088 15.2589 5.3335 19.7812 5.3335 25.0336L5.3335 37.3337C5.3335 44.801 5.3335 48.5347 6.78675 51.3869C8.06506 53.8957 10.1048 55.9355 12.6136 57.2138C15.4658 58.667 19.1995 58.667 26.6668 58.667L37.3335 58.667C44.8009 58.667 48.5346 58.667 51.3867 57.2138C53.8955 55.9354 55.9353 53.8957 57.2136 51.3869C58.6668 48.5347 58.6668 44.801 58.6668 37.3337L58.6668 25.0336C58.6668 19.7811 54.9595 15.2589 49.809 14.2288L49.4469 14.1564C46.8131 13.6296 44.4965 12.0782 43.0066 9.84335V9.84335C41.1283 7.02595 37.9663 5.33368 34.5802 5.33368L29.4201 5.33368C26.034 5.33368 22.872 7.02596 20.9937 9.84335V9.84335ZM32.0002 45.3337C37.8912 45.3337 42.6668 40.558 42.6668 34.667C42.6668 28.776 37.8912 24.0003 32.0002 24.0003C26.1091 24.0003 21.3335 28.776 21.3335 34.667C21.3335 40.5581 26.1091 45.3337 32.0002 45.3337Z"
                fill={"#605F5F"}
              />
              <path
                d="M5.33332 25.0332C5.33332 19.7808 9.0407 15.2585 14.1911 14.2284L14.5533 14.156C17.187 13.6292 19.5037 12.0778 20.9935 9.84298V9.84298C22.8718 7.02559 26.0338 5.33331 29.4199 5.33331L34.58 5.33331C37.9661 5.33331 41.1282 7.02558 43.0064 9.84298V9.84298C44.4963 12.0778 46.8129 13.6292 49.4467 14.156L49.8088 14.2284C54.9593 15.2585 58.6667 19.7808 58.6667 25.0332L58.6667 37.3333C58.6667 44.8007 58.6667 48.5344 57.2134 51.3865C55.9351 53.8953 53.8954 55.9351 51.3865 57.2134C48.5344 58.6666 44.8007 58.6666 37.3333 58.6666L26.6666 58.6666C19.1993 58.6666 15.4656 58.6666 12.6134 57.2134C10.1046 55.9351 8.06488 53.8953 6.78657 51.3865C5.33332 48.5344 5.33332 44.8007 5.33332 37.3333L5.33332 25.0332Z"
                stroke
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M42.6667 34.6666C42.6667 40.5577 37.891 45.3333 32 45.3333C26.1089 45.3333 21.3333 40.5577 21.3333 34.6666C21.3333 28.7756 26.1089 24 32 24C37.891 24 42.6667 28.7756 42.6667 34.6666Z"
                stroke
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <li className="capitalize text-[14px] sm:text-xl md:text-2xl lg:text-[32px] mt-4">
              {t("selfie")}
            </li>
          </NavLink>
        </ul>
      </nav>
      <article className="flex-1">
        <Outlet />
      </article>
    </section>
  );
}
