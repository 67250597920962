import { Outlet, useLocation } from "react-router-dom";
import Header from "./components/header";
const AppLayout = () => {
  const location = useLocation();
  //
  return (
    <div className="h-[100vh] flex flex-col">
      {["/", "/codeVerification", "/phoneVerification", "/doneOrder","/login"].includes(
        location.pathname
      ) ? null : (
        <Header />
      )}
      <main className="flex-1">
        <Outlet />
      </main>
    </div>
  );
};

export default AppLayout;
