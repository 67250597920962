


import * as Yup from "yup";
import Logo from "../icons/logo";
import BackIcon from "../icons/backIcon";
// import { useTranslation } from "react-i18next";
import { getCodeData} from "../redux/sendCodeSlice";
import {varifyCodeData } from "../redux/codeVerifySlice";
import {  useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FaRegKeyboard } from "react-icons/fa";
import { Bounce, toast } from "react-toastify";

const OTBSchema = Yup.object().shape({
  otp1: Yup.string()
    .required("Required")
    .matches(/^[0-9]$/, "Must be a number"),
  otp2: Yup.string()
    .required("Required")
    .matches(/^[0-9]$/, "Must be a number"),
  otp3: Yup.string()
    .required("Required")
    .matches(/^[0-9]$/, "Must be a number"),
  otp4: Yup.string()
    .required("Required")
    .matches(/^[0-9]$/, "Must be a number"),
});

export default function CodeVerification() {
  const dispatch = useDispatch();
  // const { t } = useTranslation();
  const navigate = useNavigate();
  //
  const [otp, setOtp] = useState({ otp1: "", otp2: "", otp3: "", otp4: "" });
  const otpRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [isShowKeyboard, setIsShowKeyboard] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const otp1Ref = useRef(null);
  //
  const codeData = useSelector((state) => state?.sendCode?.code);
  console.log("codeData", codeData);

  const varifyData = useSelector((state) => state?.codeVerify?.customerId);
  const verifyStatus = varifyData?.status
  const verifyMessage = varifyData?.message
  console.log("varifyData", varifyData);
  console.log("verifyStatus", verifyStatus);
  useEffect(() => {
    dispatch(getCodeData());
    setTimeout(() => {
      dispatch(varifyCodeData());
    },1000)
  }, [dispatch]);


  const [showCode, setShowCode] = useState(true);
  useEffect(() => {
    if (verifyMessage == "code expired") {
      setShowCode(false);
    }else{
      setShowCode(true);
    }
  }, [verifyMessage]);
  
  const code = sessionStorage.getItem("code");

  //   // ===== Mask phone number with stars =====
  const phone = sessionStorage.getItem("phone");
  const maskPhone = (phone) => {
    if (phone ) {
      const firstPart = phone.slice(0, 5);
      const lastPart = phone.slice(-2);
      return ` ${firstPart}*****${lastPart}`;
    }
    return phone;
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
    if (timeLeft == 0) {
      dispatch(varifyCodeData());
    }
    
  }, [timeLeft]);

  const handleKeyboardClick = (number) => {
    const emptyKey = Object.keys(otp).find((key) => otp[key] === "");
    if (emptyKey) {
      setOtp({ ...otp, [emptyKey]: number });
    }
  };

  const handleDelete = () => {
    const filledKeys = Object.keys(otp).filter((key) => otp[key] !== "");
    const lastFilledKey = filledKeys[filledKeys.length - 1];
    if (lastFilledKey) {
      setOtp({ ...otp, [lastFilledKey]: "" });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (otp1Ref.current) {
        otp1Ref.current.focus();
      }
    }, 0);
  }, [dispatch]);

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (value.match(/^[0-9]$/)) {
      const newOtp = { ...otp, [`otp${index + 1}`]: value };
      setOtp(newOtp);
      if (index < 3 && otpRefs[index + 1].current) {
        otpRefs[index + 1].current.focus(); // Move focus to next input if exists
      }
    } else if (value === "") {
      setOtp({ ...otp, [`otp${index + 1}`]: "" });
    }
  };

  const handleKeyDown = (e, index) => {
    if (
      e.key === "Backspace" &&
      otp[`otp${index + 1}`] === "" &&
      index > 0 &&
      otpRefs[index - 1].current
    ) {
      otpRefs[index - 1].current.focus(); // Move focus to previous input if exists
    }
  };
  const success = () =>
    toast("Order created successfully", {
      style: {
        background: " #84cc16",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "1.2rem",
        padding: "1rem",
      },
      position: "top-center",
      autoClose: 1750,
      hideProgressBar: false,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "colored",
      transition: Bounce,
    }
  );  
  const fails = () =>
    toast("Code is wrong or expired", {
      style: {
        background: "#f87171",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "1.2rem",
        padding: "1rem",
      },
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "colored",
      transition: Bounce,
    }
  );  
  return (
    <article className="relative bg-verification flex flex-col justify-center h-full">
      <div
        onClick={() => {
          sessionStorage.removeItem("phone");
          sessionStorage.removeItem("code");
          navigate(-1);
        }}
        className="absolute top-[78px] p-2.5 rounded-[18px] md:p-5 md:rounded-[30px] left-4 rtl:left-auto rtl:right-4 cursor-pointer bg-[#0000001A] hover:bg-[#00000021]"
      >
        <BackIcon
          className={
            "w-[30px] h-[30px] md:w-[40px] md:h-[40px] lg:w-[50px] lg:h-[50px] rtl:rotate-180"
          }
        />
      </div>

      <div className="mx-auto w-fit">
        <Logo className="w-[160px] h-[120px] md:w-[210px] md:h-[160px] lg:w-[285px] lg:h-[207px] " />
      </div>
      <div className="mx-auto w-full px-[27px] md:px-[37px] lg:px-[47px]">
        <h1 className="text-center text-textBold text-[38px] md:text-[76px] lg:text-[92.16px] font-medium">
          {/* {t("phoneV")} */}
          Phone Verification
        </h1>
        <p className="relative text-center text-[18px] md:text-[35px] lg:text-[48px]">
          <span
            className={` ${
              showCode ? "" : "hidden"
            } absolute -top-10 left-1/2 -translate-x-1/2 bg-slate-50 px-20 pt-10 pb-6 text-4xl font-semibold text-main rounded-2xl`}
          >
            <p
              onClick={() => setShowCode(false)}
              className="absolute top-0 left-3 text-start text-4xl font-bold text-black cursor-pointer"
            >
              x
            </p>
            <p className="flex flex-col">
              <span className="">Verify Code</span>
              <span className="text-red-500">{code}</span>
            </p>
          </span>
          {/* {t("codeSent")} */}A verification code has been sent to
        </p>
        <span className="block w-full text-center text-main text-[20px] md:text-[30px] lg:text-[48px]">
          {maskPhone(phone)}
        </span>
        <Formik
          initialValues={otp}
          validationSchema={OTBSchema}
          onSubmit={(values) => {
            const code = Object.values(values).join("");
            const sessionCode = sessionStorage.getItem("code");
            if (verifyStatus == true && code == sessionCode) {
              success();
              setTimeout(() => {
                navigate("/doneOrder");
              }, 2000);
            } else {
              fails();
            }
          }}
          enableReinitialize
        >
          <Form className="flex flex-col gap-[20px]">
            <div className="flex gap-[14px] md:gap-[24px] lg:gap-[34px] justify-center">
              {["otp1", "otp2", "otp3", "otp4"].map((field, index) => (
                <div key={index}>
                  <Field
                    name={field}
                    type="text"
                    maxLength="1"
                    className=" w-[100px] h-[120px] md:w-[140px] md:h-[160px]  lg:w-[184px] lg:h-[207px] text-[20px] md:text-[30px] lg:text-[40px] text-center border-2 border-gray-300 rounded-[26px] md:rounded-[36px] lg:rounded-[46px] focus:border-main focus:outline-none"
                    value={otp[field]}
                    innerRef={otpRefs[index]}
                    onChange={(e) => handleOtpChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  />
                  <ErrorMessage
                    name={field}
                    component="div"
                    className="text-red-500 text-[16.32px] md:text-[22.32px] lg:text-[30.32px]"
                  />
                </div>
              ))}
            </div>
              {verifyMessage == "code expired" && (
                <span className="text-center text-red-500 text-[16.32px] md:text-[22.32px] lg:text-[30.32px]">
                  ( {verifyMessage} )
                </span>
              )}
            <button
              type="submit"
              className="mainButton block w-full py-4 my-4 font-bold text-white rounded-[30px] lg:text-[40.32px]"
            >
              {/* {t("confirm")} */}
              Confirm
            </button>
          </Form>
        </Formik>
        <div className="flex items-center justify-center gap-1 md:text-[25px] lg:text-[40.32px]">
          <p className="font-medium">
            {/* {t("noCode")} */}
            Didn’t receive code?
          </p>
          <button
            onClick={() => {
              if (timeLeft == 0) {
                dispatch(getCodeData());
                setTimeout(() => {
                  dispatch(varifyCodeData());
                }, 1000);
                setTimeLeft(60);
              }
            }}
            disabled={timeLeft > 0}
            className="font-semibold text-main hover:text-mainHover"
          >
            {/* {t("resendCode")} */}
            Resend Code ({formatTime(timeLeft)})
          </button>
        </div>
        <div
          onClick={() => setIsShowKeyboard(!isShowKeyboard)}
          className="cursor-pointer mx-auto w-fit"
        >
          <FaRegKeyboard className="w-[30px] h-[30px] md:w-[40px] md:h-[40px] lg:w-[50px] lg:h-[50px]" />
        </div>
        {/* keyboard */}
        <div
          className={`${
            isShowKeyboard ? "block" : "hidden"
          } grid grid-cols-3 gap-4 my-6 w-full mx-auto`}
        >
          {["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].map((num) => (
            <button
              key={num}
              onClick={() => handleKeyboardClick(num)}
              className="p-4 bg-gray-300 text-[30px] rounded-lg"
            >
              {num}
            </button>
          ))}
          <button
            onClick={handleDelete}
            className="col-span-2 p-4 bg-main text-white text-[30px] rounded-lg"
          >
            Delete
          </button>
        </div>
      </div>
    </article>
  );
}
