


import React from 'react'
import { useNavigate } from "react-router-dom";

export default function DoneOrder() {
  const navigate = useNavigate();

  return (
    <article className="flex flex-col justify-center h-[100vh]">
      <div className="md:px-[37px] lg:px-[47px]">
        <div className="w-full flex flex-col justify-center items-center py-4">
          <img
            src="./images/doneOrder.png"
            alt="doneOrder"
            className="w-[26vw]"
          />

          <p className="text-[30px] text-center text-gray-600">
            Order Done Successfully
          </p>
        </div>
        <div
          onClick={() => {
            sessionStorage.removeItem("phone");
            sessionStorage.removeItem("cartItems");
            sessionStorage.removeItem("code");
            sessionStorage.removeItem("customer_id");
            navigate("/")
          }}
          className='cursor-pointer'
        >
          <p className="p-4 bg-main text-white text-[30px] rounded-lg text-center ">
            New Operation
          </p>
      </div>
      </div>
    </article>
  );
}
